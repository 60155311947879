<template>
  <div>
    <div class="tw-flex tw-leading-5">
      <h5 class="tw-font-semibold">{{ $t('filter.name') }}</h5>
      <a class="tw-text-gray-n3 tw-ml-4 tw-text-xs tw-no-underline sm:tw-hidden" @click="mobileVisible = !mobileVisible">{{ mobileVisible ? 'Hide' : 'Show' }}</a>
      <a class="tw-ml-auto tw-text-gray-n3" v-if="filterActive" @click="clearFilterParams">{{ $t('filter.reset') }}</a>

    </div>
    <div class="tw-space-y-5 sm:tw-block" :class="{'tw-hidden': !mobileVisible}">
      <div class="tw-grid sm:tw-grid-cols-4 tw-gap-x-6">
        <div class="tw-pt-6" v-if="sDate">
          <v-dialog
              ref="dialog"
              v-model="datesSeleted"
              :return-value.sync="dates"
              persistent
              width="320px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                  class="text-caption remove-bg-fix"
                  dense
                  v-model="dates"
                  multiple
                  chips
                  small-chips
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
                  @focus="datesSeleted=true"
                  @click:append="datesSeleted=true"
              >
                <template v-slot:selection="data">
                  <v-chip
                      small
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      :disabled="data.disabled"
                      @click:close="data.parent.selectItem(data.item)"
                      class="tw-mb-1"
                      dark
                      color="black"
                  >
                    {{dateFormat(data.item)}}
                  </v-chip>
                </template>

                <template v-slot:label>
                  <span class="caption">{{ $t('filter.search_availability') }}</span>
                </template>
                <template v-slot:append>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.6667 3.16663H3.33333C2.59695 3.16663 2 3.76358 2 4.49996V13.8333C2 14.5697 2.59695 15.1666 3.33333 15.1666H12.6667C13.403 15.1666 14 14.5697 14 13.8333V4.49996C14 3.76358 13.403 3.16663 12.6667 3.16663Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.6665 1.83337V4.50004" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.3335 1.83337V4.50004" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2 7.16663H14" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </template>
              </v-combobox>
            </template>
            <div class="tw-flex tw-flex-col tw-items-center tw-p-6 tw-bg-white">
              <p class="tw-text-xl tw-font-semibold tw-mb-4 tw-self-center">Choose dates</p>
              <v-calendar-io is-expanded class="vc-border-none" :min-date="new Date()" :attributes="attributes" @dayclick="onDayClick" />
              <Button class="tw-mt-4 tw-w-full" type="p_dark" @click="$refs.dialog.save(dates);datesSeleted = false">Apply</Button>
            </div>
          </v-dialog>
        </div>
        <div class="tw-pt-6" v-if="sLocation">
          <v-autocomplete
              class="text-caption remove-bg-fix"
              dense
              v-model="selectedCities"
              :items="cities_by_google"
              :search-input.sync="searchCities"
              :hide-no-data="!searchCities"
              :loading="loadingCities"
              hint="Boston"
              :persistent-hint="false"
              append-icon=""

              :clearable="true"
              :hide-selected="false"
              :deletable-chips="true"
              @click="clearAreas"
              :no-filter="true"
              :cache-items="false"
          >
            <template v-slot:label>
              <span class="caption">{{ $t('filter.search_location') }}</span>
            </template>

            <template v-slot:append>
              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_388_388)">
                  <path d="M14 7.16663C14 11.8333 8 15.8333 8 15.8333C8 15.8333 2 11.8333 2 7.16663C2 5.57533 2.63214 4.0492 3.75736 2.92399C4.88258 1.79877 6.4087 1.16663 8 1.16663C9.5913 1.16663 11.1174 1.79877 12.2426 2.92399C13.3679 4.0492 14 5.57533 14 7.16663Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 9.16663C9.10457 9.16663 10 8.2712 10 7.16663C10 6.06206 9.10457 5.16663 8 5.16663C6.89543 5.16663 6 6.06206 6 7.16663C6 8.2712 6.89543 9.16663 8 9.16663Z" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_388_388">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                  </clipPath>
                </defs>
              </svg>
            </template>

            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-text="data.item.description"></v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-slot:selection="data">
              <v-chip small>
                {{data.item.city_name}}
              </v-chip>
            </template>


          </v-autocomplete>

          <!---
              <input type="text" v-model="location" style="background: gray">
              <ul>
              <li v-for="(result, i) in searchResults" :key="i">
                  {{result[0].value}} {{result[1].value}} {{result[2] ? result[2].value:null}}
              </li>
              </ul>
          -->

        </div>
        <v-dialog
            v-model="showCategories"
            content-class="tw-max-w-9xl sm:tw-px-8 tw-px-4 lg:tw-px-16 tw-shadow-none"
        >
          <div class="tw-grid sm:tw-grid-cols-8">
            <div class="tw-bg-white tw-space-y-4 tw-p-10 tw-leading-6 tw-col-span-6 tw-col-start-2">
              <h3 class="tw-font-semibold tw-mb-2 tw-relative">
                Vendor categories
                <a href="#" @click.prevent="showCategories = false"
                   class="tw-right-0 tw-top-0 tw-absolute tw-text-gray-n2 tw-flex tw-items-center tw-justify-center">
                  <x-icon class="tw-h-5 tw-w-5" />
                </a>
              </h3>
              <v-list-item-group
                  v-if="sCategory"
                  multiple
                  v-model="selectedCategoriesIndices"
              >
                <template v-for="category in categories">

                  <v-list-item
                      :key="category.id"
                      @click="createCategoryFilter(category.id)"
                      link
                      active-class="tw-font-medium"
                      class="tw-inline-flex tw-bg-gray-n0 tw-mr-2 tw-font-xs tw-rounded-xl tw-mb-2 tw-min-h-0 tw-py-4 fix-bg-black"
                  >
                    <!--
                      <template v-if="1==2" v-slot:default="{ active }">
                        <v-icon
                            v-if="!active"
                            x-small
                            color="grey lighten-1"

                        >
                          mdi-checkbox-marked-circle
                        </v-icon>

                        <v-icon
                            v-else

                            x-small
                            color="pink"
                        >
                          mdi-checkbox-marked-circle
                        </v-icon>

                      </template>
                    -->
                    <template v-slot:default="{ active }">
                      <v-list-item-icon class="tw-m-0 tw-mr-1">
                        <svg class="tw-h-4 tw-w-4" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" :class="active ? 'tw-text-white':null">
                          <use :href="getCategoryImage(category.mdi)+'#' + category.mdi"/>
                        </svg>
                        <!--<img :class="active ? 'tw-text-white':null" width="20" height="20" :src="getCategoryImage(category.mdi)" alt="">-->
                      </v-list-item-icon>

                      <v-list-item-title class="mytitle">{{category.name}}</v-list-item-title>
                    </template>




                  </v-list-item>


                </template>
              </v-list-item-group>

            </div>
          </div>
        </v-dialog>
        <div class="tw-pt-6">
          <div @click="showCategories = true" class="tw-cursor-pointer tw-mt-1 tw-block tw-w-full tw-border tw-border-solid tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-n1 focus:tw-outline-none focus:tw-ring-gray-n4 focus:tw-border-gray-n4 sm:tw-text-sm tw-rounded-md">
            Categories
          </div>
        </div>
        <div class="tw-pt-6">
          <div>
            <select id="sort" v-model="sort"
                    class="tw-mt-1 tw-block tw-w-full tw-border tw-border-solid tw-pl-3 tw-pr-10 tw-py-2 tw-text-base tw-border-gray-n1 focus:tw-outline-none focus:tw-ring-gray-n4 focus:tw-border-gray-n4 sm:tw-text-sm tw-rounded-md">
              <option :value="null">Sort & filter</option>
              <option :value="orderItem.value" v-for="(orderItem, index) in orderitems" v-bind:key="index">{{ orderItem.name }}</option>
            </select>
          </div>
        </div>
      </div>
      <div
          v-if="selectedCategories.length > 0"
      >
        <template v-for="category in selectedCategories">
          <div
              :key="category.id"
              @click="removeCategoryFilter(category.id)"
              class="tw-inline-flex tw-bg-gray-n0 tw-mr-2 tw-font-xs tw-rounded-xl tw-mb-2 tw-min-h-0 tw-p-4 fix-bg-black"
          >
            <div class="tw-m-0 tw-mr-1">
              <svg class="tw-h-4 tw-w-4" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <use :href="getCategoryImage(category.mdi)+'#' + category.mdi"/>
              </svg>
              <!--<img :class="active ? 'tw-text-white':null" width="20" height="20" :src="getCategoryImage(category.mdi)" alt="">-->
            </div>

            <div class="tw-text-xs tw-self-center">{{category.name}}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from 'axios';
import { mapGetters } from 'vuex';
import Button from '@/components/Button.vue'
import { XIcon } from "vue-feather-icons";

/*
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
*/

export default {
  name: 'DataFilter',
  props: ['orderitems', 'model', 'expend', 'sDate', 'sLocation', 'sCategory', 'dCategories', 'sOrder', 'sName', 'sSearchData'],
  components: {
    Button,
    XIcon,
  },
  metaInfo() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }]
    }
  },
  data() {
    return {
      mobileVisible: false,
      categories: [],
      selectedCities: [],
      filterCountry: [],
      filterArea: {},
      filterCategories: [],
      filterdates: {},
      availability_between: null,
      category_in: null,
      filterActive: false,
      cities: [],
      searchCities: null,
      searchCitiesTemp: null,
      searchInput: null,
      loadingCities: false,
      loadingCategories: false,
      expandOnHover: false,
      mini: false,
      background: false,
      showCategories: false,
      selectedCategoriesIndices: [],
      dates: [],
      name: null,
      nameTmp: null,
      searchData: null,
      searchDataTmp: null,
      datesSeleted: false,
      loading: false,
      sort: null,
      // Google Maps API
      location: '',
      searchResults: [],
      service: null,
      mapInitialized: false,
      cities_by_google: [],
      // END
    }
  },
  watch: {
    resetFilterFlag: function () {
      if (this.resetFilterFlag === true) {
        this.resetFilter();
      }
    },
    'categories': function () {

      // to populate filter values what came from HomePage
      if (this.$route.query.category_in) {

        let id = parseInt(this.$route.query.category_in);
        this.createCategoryFilter(id);
        var cat = Object.values(this.categories);
        //console.log(this.$route.query.category_in);
        const index = cat.map(item => item.id).indexOf(id);
        //const index = cat.indexOf(this.$route.query.category_in);
        this.selectedCategoriesIndices.push(index);

      }
    },
    'sort': "orderBy",
    height: function () {
      //console.log(this.height);
      if (this.height <= 400) {
        this.expandOnHover = true;
      } else {
        this.expandOnHover = false;
      }
    },
    filterProducts: function () {
      if (this.resetFilterFlag) return;

      if (this.filterProducts === null || this.filterProducts.length === 0) return

      console.log("Filter by filterProducts...");

      this.applyFilter();
    },
    mapInitialized: function(){
      if (this.mapInitialized && this.$route.query.city && this.$route.query.place) {

        console.log("mapInitialized");
        var city = this.$route.query.city;

        this.service = new window.google.maps.places.AutocompleteService();
        this.service.getPlacePredictions({
          input: city,
          componentRestrictions: {country: ['us','pr']},
          types: ['(regions)']
        }, this.displaySuggestions)

        this.filterActive = true;
      }
    },
    cities_by_google: function () {
      if (this.$route.query.city && this.$route.query.place) {
        this.selectedCities =  this.cities_by_google.filter(item => item.id == this.$route.query.place)[0];
      }
    },
    selectedCities: function () {
      if (this.resetFilterFlag) return;

      if (!this.selectedCities || this.selectedCities === null) {
        this.filterCountry = [];
        //this.applyFilter();
        return;
      }

      //console.log("selectedCities");
      //this.filterCountry = this.selectedCities.toString();
      this.filterArea = {
        city_name: this.selectedCities.city_name,
        code: this.selectedCities.code,
        country: this.selectedCities.country,
        google_place_id: this.selectedCities.id

      };
      //console.log(this.filterArea);
      this.fetchAreas(this.filterArea);

    },
    filterCountry: function () {
      if (this.resetFilterFlag) return;
      if (this.filterCountry === null) return
      setTimeout(function () {
        this.applyFilter();
      }.bind(this), 200);
    },
    dates: function () {
      if (this.resetFilterFlag) return;

      if (!this.dates || this.dates === null || this.dates === "") return
      //console.log("Filter by dates...: " + this.dates);
      localStorage.setItem('filterDates',JSON.stringify(this.dates))
      this.applyFilter();

    },
    name: function () {

      if (this.nameTmp && this.name == null) {
        this.applyFilter();
        return;
      }
      if (this.name == null) return
      if (this.name.length < 3) return

      this.nameTmp = this.name;
      this.applyFilter();
    },
    searchData: function () {

      if (this.searchDataTmp && this.searchData == null) {
        this.applyFilter();
        return;
      }
      if (this.searchData == null) return
      if (this.searchData.length < 3) return

      this.searchDataTmp = this.searchData;
      this.applyFilter();
    },
    location(newValue) {
      if (newValue) {
        this.service.getPlacePredictions({
          input: this.location,
          types: ['(regions)']
        }, this.displaySuggestions)
      }
    },
    searchCities(val) {

      if (this.searchCities == null) return
      if (this.searchCities.length < 3) return
      // if(this.searchCitiesTemp == this.searchCities) return

      // Items have already been loaded
      //if (this.cities.length > 0) return

      // Items have already been requested


      //  if (this.loadingCities) return;
      //  this.loadingCities = true;

      setTimeout(function () {
        this.service.getPlacePredictions({
          input: val,
          componentRestrictions: {country: ['us','pr']},
          types: ['(regions)']
        }, this.displaySuggestions)
      }.bind(this), 300);

    }
  },
  created(){

  },
  mounted() {


    if (this.expend == false) {
      this.expandOnHover = true;
    }
    //this.queryFilter();
    this.fetchCategories();

    if (this.height <= 400) {
      this.expandOnHover = true;
    }

    // to populate filter values what came from HomePage
    if (this.$route.query.availability_dates_in) {
      this.dates = [this.$route.query.availability_dates_in];
    }
    if (!this.mapInitialized && this.$route.query.city && this.$route.query.place) {
      this.MapsInit();
    }
  },
  computed: {
    selectedCategories() {
      return this.categories.filter(item => this.filterCategories.includes(item.id));
    },
    Dates() {
      return this.dates.map(day => new Date(day));
    },
    attributes() {
      return this.Dates.map(date => ({
        highlight: {
          class: 'tw-bg-red',
        },
        dates: date,
      }));
    },
    ...mapGetters({
      filterProducts: 'filter/getFilterPoductsData',
      orderby: 'filter/getOrderData',
      filterParams: 'filter/getFilterParams',
      resetFilterFlag: 'filter/getResetFilterFlag',
    }),
    errorMessage() {
      if (!this.filterdates) return 'Date is required.';
      return '';
    },
    bg() {
      return this.background ? 'https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg' : undefined
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 220
        case 'sm':
          return 400
        case 'md':
          return 500
        case 'lg':
          return 600
        case 'xl':
          return 800
        default:
          return 0
      }
    },
  },
  methods: {
    onDayClick(day) {
      const idx = this.dates.findIndex(d => d === day.id);
      if (idx >= 0) {
        this.dates.splice(idx, 1);
      } else {
        this.dates.push(day.id);
      }
      console.log(day)
    },
    clearAreas() {
      console.log("clearAreas...");
      this.MapsInit();
      //this.selectedCities = null;
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService();
      this.mapInitialized = true;
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      //this.searchResults = predictions.map(prediction => prediction.description)
      this.searchResults = predictions.map(prediction => prediction.terms);

      let data = predictions.map(prediction => prediction);

      this.cities_by_google = data.map(t => ({

        id: t.place_id,
        description: t.description,
        city_name: t.terms[0].value,
        code: t.terms[1] ? t.terms[1].value : null,
        country: t.terms[2] ? t.terms[2].value : null,
      }));

    },
    orderBy() {
      //console.log("Sort: ", this.sort);
      if (!this.sort) {
        this.$store.dispatch('filter/clearOrderData');
      } else {
        this.$store.dispatch('filter/setOrderData', this.sort);
      }
      //console.log("order by: " +  this.orderby);
      this.applyFilter();

    },
    /*
    queryFilter(){

        if(this.category_query){
            //this.category_in = this.category_query;
            //this.filterCategories = this.filterCategories.concat(this.category_in)
            //this.applyFilter();
            this.filterActive = true;
        }
    },
    */
    async clearFilterParams() {
      await this.$store.dispatch('filter/setResetFilters', true);
    },
    async resetFilter() {
      //this.resetfilter = true;
      this.category_in = null;
      this.sort = null;
      this.name = null;
      this.searchData = null;

      this.filterActive = false;
      this.selectedCategoriesIndices = [];
      this.selectedCities = null;
      //this.filterCities = [];
      this.filterCategories = [];
      this.filterdates = [];
      this.availability_between = [];
      this.dates = [];
      await this.$store.dispatch('filter/clearFilterProductsData')
      await this.$store.dispatch('filter/clearOrderData');
      await this.$store.dispatch('filter/clearFilterParams');

      //$("[type='checkbox']").prop("checked", false);
      //this.uncheckElements();
      //this.uncheckElements();
      //this.resetfilter = !this.resetfilter;
      await this.$store.dispatch('filter/setResetFilters', false);

      if (this.model === "feed") await this.$store.dispatch('feed/fetchFeed');
    },
    convertDate(date) {
      var Day = new Date(date);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      var yyyy = date.getFullYear();
      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }
      Day = yyyy + '-' + mm + '-' + dd;
      return Day;
    },
    createCalendarFilter() {
      if (Object.values(this.filterdates).length == 2) {
        this.availability_between = this.convertDate(this.filterdates.start) + ',' + this.convertDate(this.filterdates.end);
        this.applyFilter();
        this.filterActive = true;
        console.log(this.availability_between);
      }
    },

    fetchAreas(val) {

      if (this.searchCitiesTemp != this.searchCities) {
        this.loadingCities = false;
      }

      //let query = (val.city_name+'/'+val.code).toString();
      let query = val;
      //console.log(query);
      const params = {
        "filter[name]": query.city_name,
        //"filter[country_name]" : query,
        "google_place_id": query.google_place_id,
        "filter[country_code]": query.code ? query.code.substring(0, 2):null,
        //"filter[filter_all]" : query,
        "page": this.$route.query.page
      };

      this.$http.get('api/areas', {params})
          .then(({data}) => {
            //this.cities = this.cities.concat(data.data);
            this.cities = data.data;
            this.filterCountry = this.cities.map(a => a.country_id);

          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          })
          .finally(() => this.loadingCities = false)

    },
    fetchCategories() {

      if (this.dCategories) {
        this.categories = this.dCategories;
        return true;
      }

      this.loadingCategories = true;
      this.$http.get('api/categories', {params: this.$route.query})
          .then(({data}) => {
            this.categories = data.data
          })
          .catch(error => {
            console.log('Error loading categories data: ' + error)
            this.errored = true
          })
          .finally(() => this.loadingCategories = false)


    },
    createCategoryFilter(id) {
      //console.log(category);
      var index = this.filterCategories.indexOf(id);
      if (index == -1) {
        this.filterCategories = this.filterCategories.concat(id)
      } else if (index > -1) {
        this.filterCategories.splice(index, 1);
      }
      this.category_in = Object.values(this.filterCategories).toString();
      this.debounce(() => this.applyFilter(), 300)();
      this.filterActive = true;
    },
    removeCategoryFilter(id) {
      let index = this.filterCategories.indexOf(id);

      if (index > -1) {
        this.filterCategories.splice(index, 1);
      }

      index = this.categories.findIndex(item => item.id === id);
      let indexInIndices = this.selectedCategoriesIndices.indexOf(index);

      if (indexInIndices > -1) {
        this.selectedCategoriesIndices.splice(indexInIndices, 1);
      }

      this.category_in = Object.values(this.filterCategories).toString();
      this.debounce(() => this.applyFilter(), 300)();
      this.filterActive = true;
    },

    debounce(func, timeout = 300){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },

    async applyFilter() {


      if (this.resetFilterFlag) return;
      this.filterActive = true;

      if (Object.values(this.filterProducts).length == 0) {
        var filterProducts = null;
      } else {
        filterProducts = this.filterProducts.result.id
      }

      if (this.dates.length == 0) {
        var dates = null;
      } else {
        dates = this.dates.toString()
      }

      if (this.filterCountry.length == 0) {
        var filterCountry = null;
      } else {
        filterCountry = this.filterCountry.toString()
      }

      if (!this.name) {
        var name = null;
        this.nameTmp = null;
      } else {
        name = this.name.toString();
      }

      if (!this.searchData) {
        var searchData = null;
        this.searchDataTmp = null;
      } else {
        searchData = this.searchData.toString();
      }

      const params = {
        "filter[name]": name,
        "filter[queryData]": searchData,
        "filter[availability_between]": this.availability_between,
        "filter[availability_dates_in]": dates,
        "filter[category_in]": this.category_in ? this.category_in : null,
        "filter[product_in]": filterProducts,
        "filter[countries_in]": filterCountry,
        "sort": this.orderby ? this.orderby : null,
        "page": 1
      };

      //this.params = this.$route.query;
      //console.log(params);
      await this.$store.dispatch('filter/setFilterParams', params);
      // console.log(this.$route.query);
      //console.log("C" + Object.values(this.filterCategories).toString() + "END");
      if (searchData || name || this.orderby || this.dates.length || this.filterCategories.length || Object.values(this.filterProducts).length != 0 || this.filterCountry.length) {
        if (this.model == "feed") await this.$store.dispatch('feed/fetchFeed', params);
        //if (this.model == "showcasework") this.$emit('showcaseworkFilter', params)
        if (this.model == "showcasework") this.$emit('showcaseworkFilter', true)
      } else {
        if (this.model == "feed") await this.$store.dispatch('feed/fetchFeed');
        if (this.model == "showcasework") this.$emit('showcaseworkFilter', false)

        this.filterActive = false;
      }


    },
    uncheckElements() {
      var uncheck = document.getElementsByTagName('input');
      for (var i = 0; i < uncheck.length; i++) {
        if (uncheck[i].type == 'checkbox') {
          uncheck[i].checked = false;
        }
      }
    },
    uncheckButtons() {
      var uncheck = document.getElementsByTagName('button');
      for (var i = 0; i < uncheck.length; i++) {
        if (uncheck[i].classList.contains("active")) {
          uncheck[i].classList.remove("active");
        }
      }
    },
    getCategoryImage(iconName) {
      try {
        return require('@/assets/img/categories/' + iconName + '.svg');
      } catch (e) {
        return null;
      }
    }
  }
}
</script>


<style scoped>

button {
  margin: 3px;
}
.mytitle {
  font-size: 0.75rem !important;
}


</style>
