<template>
  <div class="tw-max-w-9xl tw-mx-auto sm:tw-px-8 tw-px-4 lg:tw-px-16">
    <spinner v-if="!feedData"></spinner>
    <div class="md:tw-grid md:tw-grid-cols-8 md:tw-gap-x-8 tw-text-left" v-else>
      <!-- Filter -->
      <div id="filter" class="md:tw-col-span-8">

        <!-- Feed Search & Sort -->
        <Feed-filter model="feed"
                     :expend="true"
                     :sDate="true"
                     :sLocation="true"
                     :sOrder="true"
                     :sCategory="true"
                     :orderitems="orderitems"
        ></Feed-filter>
      </div>

      <!-- Feed  -->
      <div id="feed items" class="md:tw-col-span-8">

        <div v-if="feedData.length > 0" class="tw-mt-6">
          <div class="tw-mb-4 tw-flex tw-justify-between tw-font-semibold">
            <h3 class="tw-text-black tw-text-xl">Vendors</h3>
            <span class="tw-text-sm tw-text-gray-n3">{{ feedMeta.total }} results</span>
          </div>
          <!-- Feed items -->
          <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 xl:tw-grid-cols-4 md:tw-gap-x-8 tw-gap-y-4">
            <div v-for="(feed, index) in (feedData)" :key="index">
            <feed-vendor  @pinned="sortPinned" :vendor=feed :user=user />
            </div>
            <button
                v-scroll="onScroll"
                v-show="fab"
                class="tw-flex tw-justify-center tw-items-center tw-shadow tw-fixed tw-right-4 tw-bottom-4 tw-h-14 tw-w-14 tw-bg-gray-n0 tw-rounded-full"
                @click="toTop"
            >
              <arrow-up-icon />
            </button>
          </div>

          <!-- Feed pagination -->
          <div class="text-center tw-mt-4">
            <v-pagination
                v-model="pagination"
                :length="feedMeta.last_page"
                :total-visible="0"
            ></v-pagination>
          </div>
        </div>
        <div v-else-if="!getFeedLoading && feedData.length === 0">
          <div class="container tw-flex tw-justify-center tw-items-center">
            <div class=" tw-text-black tw-align-middle tw-flex tw-flex-col tw-justify-center tw-items-center ">
              <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M65.2747 10.548C77.7353 10.7239 90.7646 10.2172 101.151 17.0926C111.771 24.1221 118.24 35.8434 121.899 48.0297C125.496 60.007 126.712 73.2839 121.43 84.6212C116.419 95.3773 104.262 99.8044 94.577 106.674C85.0256 113.45 76.8601 122.712 65.2747 124.454C52.6569 126.35 39.6631 123.211 28.7771 116.564C17.269 109.537 5.16932 99.8688 3.1902 86.5449C1.25473 73.5149 14.6239 63.8111 19.1007 51.4203C23.3528 39.6515 19.2774 24.3685 28.6533 16.0691C38.1233 7.68647 52.6208 10.3693 65.2747 10.548Z" fill="#F8EEE7"/>
                <path d="M35.9394 98.5115C46.7564 88.1835 56.4952 76.8254 66.1503 65.4234C69.009 62.0476 64.1497 57.1386 61.2685 60.5416C51.6125 71.9436 41.8737 83.3004 31.0576 93.6297C27.8304 96.7091 32.721 101.585 35.9394 98.5115Z" fill="#523C39"/>
                <path d="M61.4073 30.4694C61.909 22.5465 69.9113 15.7004 76.0881 11.6936C83.9395 6.60239 94.7106 8.84211 102.538 12.8505C109.123 16.2213 113.84 22.2553 116.316 29.1288C119.332 37.506 118.237 45.0916 113.687 52.6028C108.928 60.4591 103.379 65.0382 94.4522 67.3743C87.0078 69.3227 79.7393 68.5239 72.8854 65.0518C59.9691 58.5081 56.8037 43.6678 60.7077 30.5251C61.9786 26.2462 55.3137 24.4311 54.05 28.6891C48.8005 46.3593 54.8641 64.4859 72.0487 72.2832C80.9276 76.3123 90.2537 75.8982 99.4194 73.1534C110.413 69.8618 117.266 61.3072 122.269 51.3666C130.9 34.2124 119.293 12.0473 102.492 5.22825C92.2843 1.08504 80.9126 0.342513 71.4013 6.51032C63.3492 11.7308 55.1327 20.4653 54.5001 30.4694C54.2191 34.9184 61.1255 34.8913 61.4059 30.4694H61.4073Z" fill="#523C39"/>
                <path d="M90.1196 43.3197C89.9435 43.1011 89.7657 42.8801 89.5865 42.6571C86.4185 38.7174 82.7659 34.175 78.473 31.5675L78.1441 32.2253L77.5861 31.9172M90.1196 43.3197L77.1521 30.8551L77.1518 30.8557L77.8226 31.1911L77.4603 31.847C77.4603 31.847 77.4604 31.847 77.4605 31.8471C77.5024 31.8702 77.5443 31.8936 77.5861 31.9172M90.1196 43.3197C90.5913 43.9045 91.0973 44.6176 91.6384 45.3801M90.1196 43.3197L91.6384 45.3801M77.5861 31.9172C81.973 34.3893 85.709 39.0336 89.0333 43.1662C89.2018 43.3757 89.3693 43.5838 89.5357 43.7903L89.5358 43.7905C89.9921 44.3563 90.4846 45.0502 91.0299 45.8186C91.0792 45.888 91.1289 45.958 91.1791 46.0287C91.7775 46.8711 92.4309 47.7825 93.1318 48.6444C94.508 50.3368 96.1801 51.9861 98.154 52.4908L98.1543 52.4909C98.9664 52.6982 99.7082 52.5443 100.289 52.1468C100.854 51.7604 101.228 51.172 101.398 50.5562C101.567 49.9409 101.547 49.2444 101.25 48.6287C100.944 47.9934 100.37 47.502 99.5579 47.2946L99.5578 47.2945C99.4343 47.263 99.2375 47.1573 98.9703 46.9244C98.713 46.7001 98.4417 46.4036 98.166 46.0682C97.8919 45.7346 97.628 45.3806 97.3802 45.0455C97.359 45.0169 97.3379 44.9882 97.3167 44.9596C97.0983 44.664 96.8846 44.3746 96.7026 44.1567L96.7025 44.1566C95.9457 43.2505 95.2102 42.3248 94.4686 41.3907L94.4347 41.3481C93.7061 40.4304 92.9711 39.5047 92.2139 38.5955L92.1241 38.4877C88.8014 34.4982 84.8936 29.806 80.1437 27.1837L80.1435 27.1836C79.4102 26.7785 78.6535 26.7402 77.9905 26.9761C77.3456 27.2057 76.8326 27.678 76.5105 28.2295C76.1886 28.7804 76.0294 29.4588 76.1597 30.1298C76.2942 30.8224 76.7261 31.4424 77.4601 31.8476L77.5861 31.9172ZM91.6384 45.3801C93.4634 47.9517 95.6878 51.0861 98.3398 51.7642L91.6384 45.3801Z" fill="#523C39" stroke="#523C39" stroke-width="1.5"/>
                <path d="M100.594 31.3188L100.594 31.3187C101.997 30.323 101.986 28.6609 101.355 27.5727C101.037 27.0229 100.528 26.5479 99.8747 26.348C99.1958 26.1401 98.4519 26.2583 97.7673 26.7439L97.7673 26.744C93.5827 29.7126 90.0425 33.3337 86.6233 36.9982C86.0964 37.5629 85.5726 38.1283 85.0499 38.6926C82.1663 41.8056 79.3139 44.8849 76.1328 47.6305C75.4973 48.1788 75.204 48.8732 75.2363 49.5813C75.2674 50.2637 75.596 50.8778 76.047 51.3266C76.9411 52.2167 78.547 52.6416 79.857 51.5104L80.1761 51.2348L80.1757 51.2324C83.2064 48.5755 85.9393 45.5937 88.6388 42.6482C89.1217 42.1213 89.6035 41.5956 90.0858 41.0731C93.3735 37.5113 96.6947 34.0842 100.594 31.3188Z" fill="#523C39" stroke="#523C39" stroke-width="1.5"/>
              </svg>
              <br>
              <div class=" tw-text-center tw-leading-10">
              <h2 class="tw-font-semibold">Nothing found.</h2>
              <p>We are working hard on adding the best vendors on M&L, please check back.</p>
              </div>
              <Button type="s_red" @click="resetFilter">Clear Filters</Button>
            </div>
          </div>
        </div>
        <div v-else class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 md:tw-gap-x-8 tw-gap-y-4 tw-mt-6">
          <Skeleton v-for="index in 8" :key="index"/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

import FeedVendor from '../components/FeedVendor.vue';
import FeedFilter from '../components/DataFilter.vue';
//import Search from '../components/Search'
import i18n from "@/i18n";
import { ArrowUpIcon } from "vue-feather-icons";
import Skeleton from '@/components/SkeletonBoxFeed.vue'
import Button from '@/components/Button.vue'

export default {
  //middleware: ['guest'],
  components: {
    //Feed
    FeedVendor,
    FeedFilter,
    //Search,
    ArrowUpIcon,
    Skeleton,
    Button,
  },
  metaInfo() {
    return {
      title: i18n.t('navbar.book_vendor'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.feed')
        }
      ]
    }
  },
  data() {
    return {
      loading: true,
      fab: false,
      vendorData: [],
      alldata: [],
      feeds: [],
      feeds2: [],
      meta: {},
      links: {},
      busy: false,
      //categories: [],
      cmeta: {},
      clinks: {},
      //cloading: false,
      errored: false,
      /*
      orderby: null,
      orderitems: [
        {'name': 'latest', 'value' : '-id'},
        {'name': 'oldest', 'value' : 'id'},
        {'name': 'name a-z', 'value' : 'name'},
        {'name': 'name z-a', 'value' : '-name'},
        {'name': 'love weight min', 'value' : 'reaction_love_weight'},
        {'name': 'love weight max', 'value' : '-reaction_love_weight'},
        {'name': 'rating min', 'value' : 'avg_rating'},
        {'name': 'rating max', 'value' : '-avg_rating'},
        ],
      ordersearch: null
      */
      orderitems: [
        //{'key': '1', 'name': 'Name A-Z', 'value': 'name', 'icon': 'mdi-alphabetical', 'color': 'black'},
        //{'key': '1', 'name': 'Name Z-A', 'value': '-name', 'icon': 'mdi-alphabetical', 'color': 'grey'},
        /*
        {
          'key': 'lmax',
          'name': 'Love weight max',
          'value': '-reaction_love_weight',
          'icon': 'mdi-heart',
          'color': 'red'
        },
        {'key': 'lmin', 'name': 'Love weight min', 'value': 'reaction_love_weight', 'icon': 'mdi-heart', 'color': ''},
        */
        {'key': 'rmax', 'name': 'Highest Rating', 'value': '-avg_rating', 'icon': 'mdi-star', 'color': 'yellow'},
        {'key': 'rmin', 'name': 'Lowest Rating', 'value': 'avg_rating', 'icon': 'mdi-star', 'color': ''},
        {'key': 'rmax', 'name': 'Highest Price', 'value': '-avg_price', 'icon': 'mdi-star', 'color': 'yellow'},
        {'key': 'rmin', 'name': 'Lowest Price', 'value': 'avg_price', 'icon': 'mdi-star', 'color': ''},
        /*
        {'key': '1', 'name': 'Latest created', 'value': '-id', 'icon': 'mdi-chevron-double-right', 'color': 'black'},
        {'key': '1', 'name': 'Oldest created', 'value': 'id', 'icon': 'mdi-chevron-double-left', 'color': 'grey'},
        */
      ],
    }
  },

  computed: {
    ...mapGetters({
      unaivabilityData: 'feed/getUnavailability',
      feedData: 'feed/getFeedData',
      feedLinks: 'feed/getFeedLinks',
      feedMeta: 'feed/getFeedMeta',
      user: 'auth/user',
      getFeedLoading: 'feed/getFeedLoading',
      getFilterParams: 'filter/getFilterParams',
    }),
    pagination: {
      get () {
        return this.$store.state.filter.filterParams.page
      },
      set (value) {
        this.getFilterParams.page = value;
        this.$store.dispatch('filter/setFilterParams', this.getFilterParams);
      }
    }
  },
  mounted() {
    this.loadData();
  },

  methods: {
    sortArrays(arrays) {

      if(!this.user) return arrays;
      return arrays.slice().sort(function (a, b) {
        return (a.pin < b.pin) ? 1 : -1;
      });

    },
    sortPinned(pinned){
      //console.log("pinned",pinned.item);
      this.feedData.find(x => x.id === pinned.item.id).pin = pinned.item.pin;
      this.feedData.unshift(this.feedData.find(x => x.id === pinned.item.id));
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    async switchPage() {
      const params = {
        "page": this.pagination ? this.pagination : this.$route.query.page
      };
      await this.$store.dispatch('filter/setFilterParams', params)
      await this.$store.dispatch('feed/fetchFeed', this.getFilterParams)

    },
    async loadData() {
      await this.$store.dispatch('filter/clearFilterParams')
      this.fetchFeed();
      //await this.fetchCategories();
    },
    /*
    orderBy(){
      if(!this.orderby){
         this.$store.dispatch('filter/clearOrderData');
        return;
      }
      console.log("order by: " +  this.orderby.value.toString());
      this.$store.dispatch('filter/setOrderData',this.orderby.value.toString());
    },
    */
    async fetchFeed() {
      const params = {
        "filter[category_in]": this.$route.query.category_in ? this.$route.query.category_in : null,
        "filter[availability_dates_in]": this.$route.query.availability_dates_in ? this.$route.query.availability_dates_in : null,
        "filter[countries_in]": this.$route.query.countries_in ? this.$route.query.countries_in : null,
        "page": this.$route.query.page
      };

      //this.$route.query
      if (this.$route.query.category_in || this.$route.query.availability_dates_in || this.$route.query.countries_in) {
        await this.$store.dispatch('feed/fetchFeed', params)
        this.loading = false;
      } else {
        await this.$store.dispatch('feed/fetchFeed').then(() => {
          this.loading = false;
        })
      }
      //this.loading = false;
      /*
      this.$http.get('api/feed', { params: this.$route.query })
           .then(({data})  => {
               //this.alldata = this.alldata.concat(data)
               this.feeds = this.feeds.concat(data.data)
               this.links = data.links
               this.meta = data.meta
               //console.log(this.alldata)
           })
          .catch(error => {
              console.log('Error loading feed data: ' + error)
              this.errored = true
           })
          //.finally(() => this.loading = false)
      */

    },
    /*
    fetchCategories () {
            this.cloading = true;
            this.$http.get('api/categories', { params: this.$route.query })
                 .then(({data})  => {
                     this.categories = data.data
                     this.clinks = data.links
                     this.cmeta = data.meta
                 })
                .catch(error => {
                    console.log('Error loading feed data: ' + error)
                    this.errored = true
                 })
                .finally(() => this.cloading = false)
    }
    */
    async resetFilter() {
      await this.$store.dispatch('filter/setResetFilters', true);
    },

  },
  watch: {
    "pagination": "switchPage",

    "feedData": function () {

      /*
      this.feedData.forEach((element, index) => {
        if(element.pin){
          console.log("unshift",element);
          this.feedData.unshift(this.feedData.splice(index, 1)[0]);
        }
      });
      */

      //this.vendorData =  this.feedData.sort((a, b) => a[1] - b[1]);
    }
    //'orderby': "orderBy"
  }

}
</script>
